import React from "react";
import { content } from "./content";
import { Container, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";

export default function Connect() {
  const connectInfo = content.connect_info;
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>Connect Info</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table responsive borderless size='sm'>
              <tbody>
                {connectInfo.map((connectDetail, index) => {
                  return (
                    <tr key={"tr_" + index}>
                      <th>{connectDetail.header}</th>
                      <td>
                        {(connectDetail.type === "link" | connectDetail.type === "mailto")? (
                          <a href={connectDetail.type === "mailto" ? ('mailto:' + connectDetail.details) : connectDetail.details}>
                            {connectDetail.details}
                          </a>
                        ) : (
                          connectDetail.details
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
