import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./home";
import Connect from "./connect";
import CategorySheet from "./categorySheet";
import { content } from "./content";
import { Article } from "./article";
import Articles from "./articles";
import Services from "./services";

function App() {
  return (
    <>
      <Navbar bg="black" variant="dark" expand="md" sticky="top">
          <Navbar.Brand className="ms-1" href="/">Rick Molenaar</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="me-1 ms-1" />
          <Navbar.Collapse id="responsive-navbar-nav" className="me-1 ms-1">
            <Nav className="me-auto">
              <NavDropdown title="Services" id="servicesDropDownMenu" data-testid="servicesDropDownMenu">
              <NavDropdown.Item href="/services" key="servicesDropDownItem_overview" data-testid="servicesDropDownItem_overview">Services Overview</NavDropdown.Item>
              <NavDropdown.Divider/>
                {content.service_categories.map((category, index) => (
                  <NavDropdown.Item href={"/categorysheet/" + category.name} key={"serviceDropDownItem_" + index} data-testid={"serviceDropDownItem_" + index}>
                    {category.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown title="Articles" id="articlesDropDownMenu" data-testid="articlesDropDownMenu">
                <NavDropdown.Item href="/articles" key="articleDropDownItem_summary" data-testid="articleDropDownItem_summary">Summaries</NavDropdown.Item>
                <NavDropdown.Divider/>
                {content.articles.map((article, index) => (
                  <NavDropdown.Item href={"/articles/" + article.link} key={"articleDropDownItem_" + index} data-testid={"articleDropDownItem_" + index}>
                    {article.title}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <Nav.Link href="/connect" data-testid="connectMenu">Connect</Nav.Link>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="categorysheet/:id" element={<CategorySheet />} />
          <Route path="services" element={<Services/>} />
          <Route path="articles" element={<Articles/>}/>
          <Route path="articles/:id" element={<Article/>}/>
          <Route path="connect" element={<Connect />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
