import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { content } from "./content";
import { CategoryCard } from "./categoryCard";

export default function Services() {
  return (
    <Container fluid>
    <Row className="mt-1 ms-1">
        {content.service_categories.map((category, index) => (
        <Col sm={6} lg={3} className="mb-5" key={"serviceCategory_" + index}>
            <CategoryCard data={category} />
        </Col>
        ))}
    </Row>
    </Container>
  );
}