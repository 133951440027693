import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { content } from "./content";
import { Card } from 'react-bootstrap';

function ArticleCard({ data }) {
    return (
        <Card className = "h-100 shadow-sm bg-white rounded">
            <Card.Img variant="top" src={data.image} />
            <Card.Body className="d-flex flex-column">
                <div className="d-flex mb-2 justify-content-between">
                    <Card.Title className="mb-0 font-weight-bold">{data.title}</Card.Title>
                </div>
                <Card.Subtitle>{data.date}</Card.Subtitle>
                <p/>
                <Card.Text className="text-secondary">{data.summary}</Card.Text>
                <a href={"/articles/" + data.link} className="btn btn-dark mt-auto font-weight-bold">Read it</a>
            </Card.Body>
        </Card>
    )
}

export default function Articles() {
    return (
      <>
        <Container fluid>
          <Row className="mt-1 ms-1">
            {content.articles.map((article, index) => (
              <Col sm={6} lg={3} className="mb-5" key={"article_" + index}>
                <ArticleCard data={article} />
              </Col>
            ))}
          </Row>
        </Container>
      </>
    );
  }
  