import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';

export default function Home() {
  return (
    <>
      <Container className="mt-2">
        <Row>
        <Col sm={6} lg={4} align="center">
            <Image
              fluid
              src="./images/rmprofilepicforweb.jpg"
              alt="Rick Molenaar"
              data-testid="profileImage"
            />
          </Col>
          <Col>
            <h1>Welcome to my digital home !</h1>
            <p>
              Over the last 25 years, I've lived and worked in different corners
              of the world, making a difference as a software engineer and later
              on in leadership roles.
            </p>
            <p>
              This website allows me to still play with technology while giving
              you a chance to get to know me either personally or professionally
            </p>
            <p>Allow me to present you with the following possibilities:</p>
            <a className="btn btn-dark" href="/services">
              Hire me
            </a>
            &nbsp;
            <a className="btn btn-dark" href="/articles">
              Read my articles
            </a>
            &nbsp;
            <a className="btn btn-dark" href="/connect">
              Let's connect
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}
