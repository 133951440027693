import React from "react";
import { content } from "./content";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Image} from "react-bootstrap";

export default function CategorySheet() {
    let params = useParams();
    let categoryName = params.id;

    const category = content.service_categories.find(category => category.name === categoryName);

  return (
    <Container className="mt-1">
      <Row align="center" className="mt-1 ms-1">
        <Col><h1>{category.name}</h1></Col>
      </Row>
      <Row className="mt-1">
        <Col sm={10} md={8} lg={4}><Image fluid rounded src={category.image}/> </Col>
        <Col >
          <p>{category.why}</p>
          <p>{category.details}</p>
        </Col>
      </Row>
      <Row align="right" className="mt-3">
        <Col>
          <a href={"/connect"} className="btn btn-dark mt-auto font-weight-bold">Let's connect</a>
        </Col>
      </Row>
    </Container>
  );
}
