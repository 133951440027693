export const content = {
  service_categories: [
    {
      name: "Interim Management",
      image: "/images/team-planning-1000.jpg",
      description:
        "Temporarily in need an experienced manager to address a skills gap in your technology team leadership team or in need of a fresh persperspective, consider me as an interim solution",
      why: "You might find that right now is not the best time to bring in a new full time manager or you simply haven't found the right candidate just yet. Bringing in Interim managers is also a great way to bring in new perspectives. Interim managers offer an organization a new viewpoint because they are an impartial external resource.",
      details:
        "Consider me for the following technology leadership positions: CTO, CPTO, Head of Engineering, Engineering Manager, Head of Project Management Office, Project Manager, Program Manager etc.",
    },
    {
      name: "Project  Management",
      image: "/images/wet-web-1000.jpg",
      description:
        "Do you want to increase the likelyhood of your project to be succesful. Consider hiring me as a Project Manager.",
      why: "Having a dedicated Project Manager improves the chances of achieving the results you aimed for. Project Managers also bring fresh perspectives on your project and will ensure that the right priority is given by the projects resrouces. On top of that, Project Managers will secure effective communication and will control project constraints like time, budget, quality & risks.",
      details:
        "Depending on existing organizational processes and culture we pick the right approach to managing your project. Agile delivery practices often work well with Software Engineering, but other parts of the project might benefit from a more 'command and control' type of project management. Lets work out a way that best meets your expectations",
    },
    {
      name: "Fractional Executive",
      image: "/images/code-1000.jpg",
      description:
        "You might not have the resources, budget or workload to hire a full time CTO, CPTO or VP of Engineering. In that case consider the fractional model: an experienced professional helping you for a fraction of the time agains a fraction of the cost.",
      why: "Wnen you do not have enough work or budget for a full time Executive to run your technology, but you do see technical challenges that require a tactical or strategic perspective. It might be that your current technology team could use some help from a senior technology leader or that you need help managing your technology provider.",
      details: (
        <>
          As fractional tech leader I would help you out in many ways as explained in the following page: {" "} 
          <a href="/categorysheet/Tech%20Leadership%20Services">
            Tech Leadership Services
          </a>
        </>
      ),
    },
    {
      name: "Tech Leadership Services",
      image: "/images/chess-1000.jpg",
      description:
        "Based on earlier experience I can help you with a specific need you have in terms of Technology Management",
      why: "When you could do with an extra pair of hands or brains to tackle a specific challenge in the field of Software/Product Engineering. It might be that you feel your tech organization, partner or leader could use some help finding ways to improve or you decide to implement a more efficient way to do performance evaluation for engineers. You might also be on the lookout for the best solution for your product challenge.",
      details: (
        <>
          Specific ways I can help you are:{" "}
          <ul>
            <li>
              analyzing and improving your software delivery organization &
              processes,
            </li>
            <li>
              coaching or mentoring tech leads, product owners or engineering
              managers
            </li>
            <li>
              setting up a Performance evaluation program optimized for software
              engineering
            </li>
            <li>
              assist in doing the technical due dilligance when choosing a
              partner or aqcuiring a company,
            </li>
            <li>
              assist in setting up KPIs and OKRs, define your tech strategy or
              lead R&D initiatives to find the best solution for your product
              related challenge.
            </li>
            <li>recruit your tech team including your new technology leader</li>
            <li>defining or tuning your tech strategy</li>
          </ul>
          You might have other needs than the above, so please reach out to
          discuss
        </>
      ),
    },
  ],
  connect_info: [
    {
      header: "LinkedIn",
      type: "link",
      details: "https://www.linkedin.com/in/rickpmolenaar/",
    },
    {
      header: "Instagram",
      type: "link",
      details: "https://www.instagram.com/rickpmolenaar/",
    },
    {
      header: "Medium",
      type: "link",
      details: "https://medium.com/@rick_42462",
    },
    {
      header: "Toptal",
      type: "link",
      details: "https://www.toptal.com/project-managers/resume/rick-molenaar",
    }
  ],
  intro: [
    "Every company needs Technical Leadership (e.g. a CTO/CTO/ VP of Engineering etc) in order to keep their competitive advantage. These professionals are  typically in charge of the technology, the tech team and/or vendors. They promote efficiency and innovation throughout the company, but also secure important initiatives  such as technical  integration, transformation, automation, stabilisation & improvement projects",
    "The expense of hiring technical leadership full-time is typically very high and the market for technical leaders is extremely difficult which makes having these professionals on your team permanently a challenge.",
    "For some companies it might be worthwhile to consider a less costly part-time or temporary solution (referred to as “Fractional”). This way  you can reap the benefits of the services provided by these professionals at a fraction of the cost.",
    "Find out how I can help you below or browse the menu to learn more about me or find out how to get in touch",
    "Have a great day !",
  ],
  articles: [
    {
      title: "The colours of Tech Leadership",
      date: "August 30, 2022",
      image: "/images/colors-of-tech-leadership.jpeg",
      link: "the_colours_of_tech_leadership",
      summary:
        "Finding a good way to do Tech Leadership is tough. When dumbing down the problem for people I often refer the Insights Discovery personality colours (with a slightly different interpretation) and how a lack of one or more of the colours can affect the teams impact.",
    },
    {
      title: "Fun with flags",
      date: "September 16, 2022",
      image: "/images/fun_with_flags.png",
      link: "fun_with_flags",
      summary:
        "Having lived in 5 different countries, we recently moved back to the Netherlands where currently the upside down version of our flag is being used as a symbol of protest by farmers against the national government. It initially made me think of Sheldon's vlog \"fun with flags\" in the Big Bang Theory. It also made me think about what the national flags I've lived under represent for me.",
    },
    {
      title: "Finding Flow",
      date: "September 7, 2022",
      image: "/images/finding_flow.png",
      link: "finding_flow",
      summary:
        'Instead of referring to "Process", I prefer to refer to "Flow". Flow implies progress and is something you can maintain for a long time. Finding that flow for yourself, your team and/or your organisation is a nice challenge, but very rewarding when you find it !.',
    },
    {
      title: "Back to the (coding) rabbit holes",
      date: "May 25, 2022",
      image: "/images/back_to_the_coding_rabbit_holes.jpeg",
      link: "back_to_the_coding_rabbit_holes",
      summary:
        "I like getting up early and trying to catch the sunrise with my dog Harry. Usually I would then soon start my journey to work, but Covid changed all that. I decided to use the extra hour in the morning to get back into coding. It's been over 15 years since I was seriously coding so it was a bit of a challenge to actually practice what I preached for so long. Here's my main insights on how the developer experience changed.",
    },
  ],
};
