import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { content } from "./content";

export function Article() {
  let params = useParams();
  let id = params.id;

  const { title, date, image } = content.articles.find(
    (article) => article.link === id
  );

  function renderArticle(id) {
    switch (id) {
      case "the_colours_of_tech_leadership":
        return (
          <>
            <div align="left">
              <p>
                Finding a good way to do Tech Leadership is tough. When dumbing
                down the problem for people I often refer the Insights Discovery
                colours (but with my own interpretation):
              </p>
              <ul>
                <li>
                  The colour green stands for people leadership i.e. building a
                  great team, enable them, mentor & coach them etc. etc.
                </li>
                <li>
                  The colour yellow stands for inspiration. This involves making
                  clear what the inspirational purpose of the team is. An
                  inspired team will pull the work rather than waiting for work
                  to be pushed their way.
                </li>
                <li>
                  The colour red stands for product/business leadership. Each
                  iteration we do, we need to be clear on what problem are we
                  trying to solve to best help our customers succeed.
                </li>
                <li>
                  The colour blue is where the technology comes in; building &
                  maintaining a solid foundation, securing & protecting
                  standards, keeping tech debt at bay etc.
                </li>
              </ul>
              <p>
                Teams work well when all colours are well represented. When it
                comes to leadership the same applies. We'd love to think that
                leaders are are strong in all areas, but in my experience,
                leaders are often most comfortable in 2 of these colours. This
                means that our tech leader needs help and different frameworks
                offer different solutions. Scrum offers roles like the product
                owner and scrum masters. Spotify introduced matrix structures
                with chapter leads & tribe leads. We also often see leadership
                roles that focus on technology (Tech lead, Principal), leaving
                people management with others.
              </p>
              <p>
                Now all of these can work well, depending on the people
                fulfilling the roles. In my experience, Engineering managers who
                excel in tech & people leadership in combination with product
                owners with a clear vision (yellow) and thorough understanding
                of the business (red) works really well.
              </p>
              <p>
                But as mentioned you will rarely find that combination and you
                face issues like this:
                <ul>
                  <li>
                    Tech leaders don\'t really like to be involved in people
                    leadership (e.g. managing under performance). (lack of
                    green)
                  </li>
                  <li>
                    People or business oriented leaders miss technical
                    expertise. Apart from the risk of technical debt, this means
                    that engineers might feel their manager does not really
                    understand them enough to judge their performance and/or can
                    help them grow. (lack of blue).
                  </li>
                  <li>
                    The people with business/product understanding don't have
                    enough time or patience to work with the team. This means
                    that we might miss opportunities for (alternate/better)
                    solutions. (lack of red)
                  </li>
                  <li>
                    Product owners are often more analytical or might even have
                    a tech background. When this happens, there is often not
                    enough focus on the why/true purpose (lack of yellow).
                  </li>
                </ul>
              </p>
              <p>
                To solve the above problems, you have to restore one of more
                colours. Assuming you have the right people on the bus, you have
                to truly understand people's colour preferences, skills as well
                as personal ambitions right now and in the future. You also have
                to find the right balance between sticking to a
                static/symmetrical organisational structure and keep things more
                fluid.
              </p>
              <p>
                No magic solution in this post (as there isn't one), but the
                colours are a good place to start.{" "}
                <a href="/connect">Connect with me</a> if you want to discuss.
              </p>
            </div>
          </>
        );
      case "fun_with_flags":
        return (
          <div align="left">
            <p>September 16, 2022</p>
            <p>
              If you travel around Holland right now you will find that farmers
              have taken the dutch flag and hung it upside down in protest
              against the dutch government. When I first saw this, I couldn't
              help but think about "Fun with Flags", a vlog hosted by Sheldon in
              the Big Bang Theory (still one of my favorite shows) and how he
              would interpret this.
            </p>
            <p>
              While some people take offence that our national flag is being
              misused as a symbol of protest, I realised that I'm not that
              patriotic. A contributing factor might be that I've lived under 5
              different flags over my life time.
            </p>
            <p>
              I grew up in Holland which is a very densely populated country
              with not necessarily the best climate (well, that seems to be
              changing). People live close to one another and it is a very
              efficient country where communication is very direct and most of
              your life was spent inside well decorated buildings.{" "}
            </p>
            <p>
              Australia helped me appreciate living outside and somehow the
              whole culture felt less narrow minded than what I was used to. I
              also adopted their main catch phrase and worry less ("no worries
              mate").{" "}
            </p>
            <p>
              Moving to Ireland helped me to become more humble and polite. I
              noticed how my directness and opinions offended people even if
              they would never say that to my face. I learned to find
              compromises and adopted ("fair enough") as one of my main sayings.{" "}
            </p>
            <p>
              I remember arriving in the South of France thinking that I had
              finally found the place where I would grow old. To this date, I
              still think the French know best how to appreciate all aspects of
              life and express their gratitude in poetic ways through an amazing
              language. While it is initially hard to connect with people, I
              discovered that investing in learning the language will help you
              find amazing friendships.{" "}
            </p>
            <p>
              Seeing the big divide between rich and poor in South Africa has
              helped me appreciate how privileged I really am to have grown up
              in a country where education, safety & social security are the
              norm. You also see how wrong things can go if we don't find better
              ways to distribute wealth.
            </p>
            <p>
              Coming back to Netherlands made me appreciate how good and well
              organised life in Holland really is. I also realised that every
              country has it's share of problems, but I'm sure that together we
              stand a better chance of solving todays common challenges.
            </p>
          </div>
        );
      case "finding_flow":
        return (
          <div align="left">
            <p>
              How to reach optimal productivity and impact is the topic of many
              books & articles. Based on all this wisdom and over time I've
              developed the following theory.
            </p>
            <p>
              In Technology, work is complex i.e. we often find out the full
              scope and complexity along the way. For this reason we prefer to
              dedicate blocks of time and during each of these blocks we try to
              make the best possible impact. This time-box is followed by a
              review on how well we've done and how we can do better. We often
              use regular calendar units like working days, weeks, quarters and
              years. Think about daily standups, quarterly performance reviews,
              regular board meetings etc. etc. , but different frameworks have
              introduced fancy time-boxes like sprints, cycles & pomodoros.
            </p>
            <p>
              Choosing the right time-box and as such rhythm is essential.
              Having the wrong rhythm will have disastrous effects on
              productivity and motivation. There are different reasons for this;
              starting and/or finishing a time-box involves ceremonies,
              producing artefacts etc. which take time to complete and also the
              time allocated to focus simply might be too short. I see for
              example that for a lot of teams, 2-week sprints are too short.
              Obviously there can be multiple and more structural reasons for
              this which I'll try to ignore this for now.
            </p>
            <p>
              Teams who's primary responsibility is to handle customer tickets
              have a different rhythm from teams that do a lot of research.
              Putting these activities in the same team makes it more complex
              for the team to find the right rhythm. Each team has their own
              optimal flow that will help them make a difference and maybe this
              is a good time to consider if the team's mission and composition
              is the right one.{" "}
            </p>
            <p>
              The whole idea of the time-box is that you focus on a given part
              of the mission and that you have something to show for at the end.
              Switching between missions (context switching) is considered
              expensive, however today's reality requires us to do just that.
              This implies that we do need to get good at context switching,
              while still finding time to focus on that topic. This is where
              personal productivity, flow and time-boxes comes in again. I
              personally love the pomodoro technique, but with a slight
              variation. I choose to dedicate a small number of pomodoros on a
              given topic based on priority of the topic and push myself to have
              some meaningful result before the end of the last pomodoro.
            </p>
            <p>
              Another insight I've discovered over time is that the best ideas
              actually don't happen while focussing, but when stepping away from
              the problem. Developers often claim that their best ideas happened
              while smoking a cigarette or going for a "bio break". I have also
              often seen that working on seemingly unrelated topics provides
              insights on how to tackle other problems that I am facing.
              Considering this actually might imply that shorter time-boxes and
              that switching between topics can be a good thing.
            </p>
            <p>
              When you or the team is in their time-box it is essential that you
              deliver something meaningful by the end. I used to dislike
              deadlines unless the presented a 'real' deadline. A real deadline
              is for example the end of a contract for a solution we are using.
              However, there's nothing wrong in pushing ourselves and make each
              time-box count. It makes success that much more rewarding. If you
              regularly fail to deliver during the time-box, it is time to
              reflect and consider if your rhythm is the right one (I personally
              really like ShapeUp for example).
            </p>
            <p></p>Once you get used to make an impact each time-box, you will
            find your flow and real progress is made. This goes for personal
            productivity, team velocity and ultimately personal and company
            success.
          </div>
        );
      case "back_to_the_coding_rabbit_holes":
        return (
          <div align="left">
            <p>
              I like getting up early and trying to catch the sunrise with my
              dog Harry. Usually I would then soon start my journey to work, but
              Covid changed all that. I decided to use the extra hour in the
              morning to get back into coding. It's been over 15 years since I
              was seriously coding so it was a bit of a challenge to actually
              practice again what I've been preached for so long. Here's my main
              insights on how the developer experience changed.
            </p>
            <ul>
              <li>
                At its core, coding hasn't changed: you still have to design,
                code, debug, build, deploy and analyse issues. But new
                languages, tooling & frameworks have made it a very different
                experience.
              </li>
              <li>
                Google search got (a lot) better at helping you find what you
                need. There is also tons and tons of content that helps you
                learn the skills you need (update 2023: chatGPT is even better)
              </li>
              <li>
                All this stuff for free means that a lot of barriers that
                existed before are gone. (Cloud capacity, Development frameworks
                & components, IDEs, etc.){" "}
              </li>
              <li>
                Development is (still) not for everyone. Logic is unforgiving,
                requires patience and is sometimes hard to find. But when that
                moment of clarity arrives it is pure bliss.
              </li>
              <li>
                EcmaScript has evolved beyond anything I considered possible at
                the time. Frameworks like NPM, React, Angular, but it's a
                challenge to keep the code readable (at least for me).
              </li>
              <li>
                Taking regular breaks (or in my case having to do the day job)
                helps you to avoid getting stuck in Rabbit Holes. 
              </li>
              <li>Never leave the
                code in a state where it is not working. For this reason
                defining the smallest possible functional gains is essential
                (aka Agile.). Technical debt creeps up very quickly if you do
                not revisit the code regularly.
              </li>
              <li>Investing in setting up DTAP, TDD and deploying frequently from the very start gives so much peace of mind, even at a small scale.</li>
              <li>
                Depending packages keep changing and staying up to date forces
                you to revisit a lot more code than I would have been
                comfortable with in the past
              </li>
            </ul>
            <p>Dealing with unforeseen complexities are still very much what defines the craft of software engineering. I will never reach the level again that I was at 15 years ago, but I've noticed that experiencing the full complexity of coding first hand again helps me to have much better conversations with the team on how to manage these situations as best as possible and that can't be bad ! </p>
          </div>
        );
      default:
        return "No Article";
    }
  }

  return (
    <Container className="mt-1">
      <Row className="mt-1 ms-1">
        <Col align="center" className="text-secondary">
          <Image src={image} />
          <h1>{title}</h1>
          <p>{date}</p>

          {renderArticle(id)}
        </Col>
      </Row>
      <Row align="right" className="mt-3">
        <Col>
          <a
            href={"/articles"}
            className="btn btn-dark mt-auto font-weight-bold"
          >
            More Articles
          </a>
          &nbsp;
          <a
            href={"/connect"}
            className="btn btn-dark mt-auto font-weight-bold"
          >
            Lets connect
          </a>
        </Col>
      </Row>
    </Container>
  );
}
